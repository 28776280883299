import { BdsButton, BdsGrid, BdsImage, BdsTypo } from 'blip-ds/dist/blip-ds-react';

import { ONBOARDING_ROUTE } from 'constants/Routes';
import { Analytics } from 'infra/adapters';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import defaultLogoBlipGo from '../../../../assets/LogoBlipGo.png';
import robotImage from '../../../../assets/robot-4.svg';
import './styles.scss';

export const InstallationError = () => {
  const navigate = useNavigate();

  useEffect(() => {
    Analytics.Track(Analytics.events.SO_INSTALLATION_OPEN_PAGE_RESTART);
  }, []);

  const retryInstallation = () => {
    Analytics.Track(Analytics.events.SO_INSTALLATION_RESTART);
    navigate(ONBOARDING_ROUTE);
  };

  return (
    <BdsGrid direction="column" alignItems="center" gap="4" padding="t-7" className="conainter">
      <BdsGrid direction="column">
        <BdsGrid justifyContent="center" data-testid="blipGo-logo">
          <BdsImage src={defaultLogoBlipGo} />
        </BdsGrid>
        <BdsGrid direction="column" alignItems="center" height="32rem" margin="t-5" className="container-body">
          <BdsImage height="12.5rem" width="12.37rem" src={robotImage} />
          <BdsTypo variant="fs-32" bold="bold" tag="h2" margin={false}>
            Desculpe! Houve um erro durante a sua instalação de Blip Go
          </BdsTypo>
          <BdsTypo variant="fs-16" bold="regular">
            Você poderá tentar novamente reiniciando a instalação.
          </BdsTypo>
          <BdsButton icon="refresh" variant="tertiary" onBdsClick={retryInstallation}>
            Reiniciar Instalação
          </BdsButton>
        </BdsGrid>
      </BdsGrid>
    </BdsGrid>
  );
};
