export enum Steps {
  SelectTemplate,
  MessagePreview,
  AnswersDirecting,
  ConfirmSendMessage,
}

export enum AnswerDirectingOptions {
  Human,
  Chatbot,
}

export type MessageCampaignBody = {
  name: string;
  templateId: string;
  destinationBot: {
    botId: string;
    flowId: string;
    stateId: string;
  };
  audiences: AudiencesBodyProp[];
};

type AudiencesBodyProp = {
  phoneNumber: string;
  parameters: AdditionalProps;
};

type AdditionalProps = {
  [key: `additionalProp${number}`]: string;
};

export type AudienceAndVariables = {
  telefone: string;
  [key: string]: string;
};
