import { BdsButton, BdsGrid, BdsIllustration, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { Alert } from 'components/Alert/Alert';
import { ApplicationDetail } from 'configurations/Environment';
import { Analytics } from 'infra/adapters';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import './style.scss';

export const ActiveMessagesNotRegistered = () => {
  const { tenant, router } = useSelector(useInstallation);
  const [openRedirectConfirmation, setOpenRedirectConfirmation] = useState(false);

  const sendActiveMessagesUrl = `https://${tenant.id}.${ApplicationDetail}/${router.shortName}/contents/messagetemplate`;

  const handleRedirectConfirm = () => {
    window.open(sendActiveMessagesUrl, '_blank');
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_SCREEN_EMPTY_CREATE);
    setOpenRedirectConfirmation(false);
  };

  return (
    <>
      <BdsPaper style={{ background: '#FFF' }}>
        <BdsGrid
          direction="column"
          justifyContent="center"
          alignItems="center"
          padding="5"
          gap="3"
          style={{ width: '100%' }}
        >
          <BdsGrid alignItems="center" gap="2">
            <BdsIllustration className="active-messages-not-registered-ilustrator" name="empty" type="spots" />
          </BdsGrid>
          <BdsGrid alignItems="center" gap="2">
            <BdsTypo variant="fs-20" margin={false} bold="bold">
              Nenhuma mensagem cadastrada
            </BdsTypo>
          </BdsGrid>
          <BdsGrid alignItems="center" gap="2">
            <BdsTypo
              className="active-messages-not-registered-text-create-template"
              variant="fs-16"
              margin={false}
              bold="regular"
            >
              Para fazer disparo em massa, é necessário cadastrar uma mensagem que passará pela aprovação do WhatsApp.
            </BdsTypo>
          </BdsGrid>
          <BdsGrid alignItems="center" gap="2">
            <BdsButton
              onBdsClick={() => setOpenRedirectConfirmation(true)}
              variant="outline"
              color="primary"
              data-testid="button-create-template"
            >
              Cadastrar nova mensagem
            </BdsButton>
          </BdsGrid>
        </BdsGrid>
      </BdsPaper>
      <Alert
        open={openRedirectConfirmation}
        title="Criar modelo ?"
        description="Você será direcionado para a plataforma Blip para criar um modelo para sua mensagem."
        icon="info"
        variant="system"
        onCancel={() => setOpenRedirectConfirmation(false)}
        onConfirm={handleRedirectConfirm}
      />
    </>
  );
};
