export const ACTIVE_MESSAGES = {
  TEMPLATE_LINK:
    'https://docs.google.com/spreadsheets/d/e/2PACX-1vSjlRSyVIMFvLWJXkRJOoPv7rO3Wocgb7z6WNX3SH36VIFSIRmwxWHKByphkx6mFr_ob6imqscMvAp9/pub?output=xlsx',
};

export const ACTIVE_MESSAGES_REJECT_REASON = [
  {
    REJECT_REASON: 'ABUSIVE_CONTENT',
    MESSAGE: 'Recusado por conter conteúdo abusivo',
  },
  {
    REJECT_REASON: 'INCORRECT_CATEGORY',
    MESSAGE: 'Recusado por conter a categoria incorreta',
  },
  {
    REJECT_REASON: 'INVALID_FORMAT',
    MESSAGE: 'Recusado por conter erros de formatação',
  },
  {
    REJECT_REASON: 'POLICY_VIOLATION',
    MESSAGE: 'O template viola as políticas da Meta, como envio de mensagens promocionais em categorias não permitidas',
  },
  {
    REJECT_REASON: 'PLACEHOLDER_MISMATCH',
    MESSAGE:
      'Erro relacionado aos placeholders ({{1}}, {{2}}, etc.), como número incorreto ou falta de correspondência com os parâmetros fornecidos',
  },
  {
    REJECT_REASON: 'TOO_LONG',
    MESSAGE: 'O conteúdo do template excede o limite permitido de caracteres',
  },
  {
    REJECT_REASON: 'UNSUPPORTED_LANGUAGE',
    MESSAGE: 'O idioma selecionado para o template não é suportado ou não corresponde ao conteúdo da mensagem',
  },
  {
    REJECT_REASON: 'INVALID_SAMPLE',
    MESSAGE: 'O exemplo fornecido para os placeholders não é válido ou não está alinhado com o conteúdo do template',
  },
  {
    REJECT_REASON: 'DUPLICATE_TEMPLATE_NAME',
    MESSAGE: 'O nome do template já foi usado para outro template ativo ou pendente',
  },
  {
    REJECT_REASON: 'UNSUPPORTED_CHARACTER',
    MESSAGE: 'O uso de caracteres não permitidos, como emojis ou símbolos especiais',
  },
  {
    REJECT_REASON: 'EXCESSIVE_CAPITALIZATION',
    MESSAGE: 'Uso excessivo de letras maiúsculas, o que pode ser interpretado como spam',
  },
  {
    REJECT_REASON: 'CONTENT_TOO_GENERIC',
    MESSAGE: 'O conteúdo é genérico demais e pode ser considerado inadequado para a finalidade pretendida',
  },
  {
    REJECT_REASON: 'MISSING_ELEMENT',
    MESSAGE: 'Faltam elementos obrigatórios, como título ou corpo do template, dependendo do tipo',
  },
];
