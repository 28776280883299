import { yupResolver } from '@hookform/resolvers/yup';
import { BdsButton, BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { VCheckbox } from 'components/Form/VCheckbox';
import { VSelect } from 'components/Form/VSelect';
import { BlipGoId } from 'configurations/Environment';
import { PROCURATOR_POSITION } from 'constants/MetaActivation';
import { Analytics, Logger } from 'infra/adapters';
import { BusinessInformartionOnboarding } from 'objects/types/OnboardingOptions';
import { businessInformartionOnboardingSchema } from 'pages/Onboarding/validations/OnboardingValidation';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useAccount } from 'redux/slices/AccountSlice/accountSlice';
import { postAcceptTermsApi } from 'services/AcceptTermsApiService';
import { createToastError } from 'services/Toats';
import { formatLogMessage } from 'utils/LogMessage';
import { VInput } from '../../../../components/Form/VInput';
import { BusinessInformationContainer } from './style';

interface IProps {
  nextPageEvent: () => void;
  setBusinessInformation: (data: BusinessInformartionOnboarding) => void;
  setIsLoading: (value: boolean) => void;
}

export const BusinessInformation = ({ nextPageEvent, setBusinessInformation, setIsLoading }: IProps) => {
  const className = 'BusinessInformation';

  const { profile } = useSelector(useAccount);
  const ACCEPT_TERM_ID = 1;

  const methods = useForm<BusinessInformartionOnboarding>({
    resolver: yupResolver(businessInformartionOnboardingSchema),
    defaultValues: {
      companyName: '',
      companyPosition: '',
      companySite: '',
      acceptTerms: false,
    },
  });

  const { handleSubmit, control } = methods;

  const onSubmit = async (data: BusinessInformartionOnboarding) => {
    const methodName = 'onSubmit';
    setIsLoading(true);
    try {
      setBusinessInformation(data);
      Analytics.Track(Analytics.events.SETUP_INSTALLATION_START, {
        company_name: data.companyName,
        website: data.companySite,
        occupation: data.companyPosition,
        acceptTerms: data.acceptTerms,
      });

      if (profile.email && !profile.email?.includes('@blip.ai')) {
        await postAcceptTermsApi({
          acceptAtDate: new Date(),
          email: profile.email,
          merchantName: data.companyName,
          packId: Number(BlipGoId),
          termId: ACCEPT_TERM_ID,
        });
      }

      nextPageEvent();
    } catch (error: any) {
      Logger.Fatal(formatLogMessage(error, 'Failed create terms'), {
        methodName,
        className,
      });
      createToastError('Falha ao aceitar termos', 'Por favor, entre em contato com suporte');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BusinessInformationContainer>
      <BdsGrid direction="column" gap="4">
        <FormProvider {...methods}>
          <BdsGrid direction="column">
            <BdsTypo bold="bold" variant="fs-32" tag="h2">
              Nos conte sobre seu negócio
            </BdsTypo>
            <BdsTypo bold="regular" variant="fs-16">
              Vamos lá! Preencha os dados essenciais para configurar o Blip Go.
            </BdsTypo>
          </BdsGrid>

          <BdsGrid direction="column" gap="3">
            <BdsGrid direction="column" gap="1">
              <BdsTypo bold="bold" variant="fs-14">
                Nome da empresa
              </BdsTypo>
              <VInput
                control={control}
                name="companyName"
                icon="company"
                placeholder="Nome fantasia"
                dataTestId="company-name"
              />
            </BdsGrid>
            <BdsGrid direction="column" gap="1">
              <BdsGrid direction="column">
                <BdsTypo bold="bold" variant="fs-14">
                  Site da empresa
                </BdsTypo>
                <BdsTypo variant="fs-12">Caso não tenha site, utilize o link de perfil do instagram</BdsTypo>
              </BdsGrid>
              <VInput
                control={control}
                name="companySite"
                icon="site"
                placeholder="https://meusite.com.br/"
                dataTestId="company-site"
              />
            </BdsGrid>
            <BdsGrid direction="column" gap="1">
              <BdsTypo bold="bold" variant="fs-14">
                Qual seu cargo na empresa?
              </BdsTypo>
              <VSelect
                control={control}
                name="companyPosition"
                options={PROCURATOR_POSITION}
                icon="business"
                placeholder="Selecione uma opção"
                dataTestId="company-positions-select"
                optionsDataTestId="company-positions-select-options"
              />
            </BdsGrid>
            <BdsGrid direction="column" gap="1">
              <VCheckbox
                control={control}
                name="acceptTerms"
                label="Declaro que li e estou de acordo com os "
                link="https://www.blip.ai/docs/termos-de-uso-blip-go/"
                textLink=" Termos de Uso"
                dataTestId="company-acceptTerms"
              />
            </BdsGrid>
          </BdsGrid>
          <BdsGrid margin="t-4" direction="row-reverse" justifyContent="space-between">
            <BdsButton
              onBdsClick={() => {
                handleSubmit(onSubmit)();
              }}
              data-testid="start-configuration"
            >
              Iniciar configuração
            </BdsButton>
          </BdsGrid>
        </FormProvider>
      </BdsGrid>
    </BusinessInformationContainer>
  );
};
