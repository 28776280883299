import { BdsButton, BdsChipTag, BdsTableCell, BdsTableRow, BdsTooltip, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { ACTIVE_MESSAGES_REJECT_REASON } from 'constants/ActiveMessages';
import { Template } from 'objects/types/MessageTemplate';
import './style.scss';

type ITemplateListItemProps = {
  template: Template;
  onTemplateClick: () => void;
};

const ButtonUseTemplate = ({ template, onTemplateClick }: ITemplateListItemProps) => {
  if (template.status.toLocaleLowerCase() === 'approved') {
    return (
      <BdsButton
        className="button-use-model"
        variant="tertiary"
        onBdsClick={onTemplateClick}
        data-testid={`template-${template.templateId}`}
      >
        Usar modelo
      </BdsButton>
    );
  }

  if (template.status.toLocaleLowerCase() === 'rejected') {
    const reason = ACTIVE_MESSAGES_REJECT_REASON.find(reason => reason.REJECT_REASON === template.rejectedReason);
    let message = 'Recusado';

    if (reason) {
      message = reason.MESSAGE;
    }
    return (
      <BdsTooltip id="tooltip" position="left-center" tooltip-text={message}>
        <BdsChipTag color="danger" icon="delete">
          Reprovada
        </BdsChipTag>
      </BdsTooltip>
    );
  }

  if (template.status.toLocaleLowerCase() === 'pending') {
    return (
      <BdsTooltip id="tooltip" position="left-center" tooltip-text="Sua mensagem está sendo avaliada pela Meta">
        <BdsChipTag color="warning" icon="clock">
          Em análise
        </BdsChipTag>
      </BdsTooltip>
    );
  }

  return <></>;
};

export const TemplateListItem = ({ template, onTemplateClick }: ITemplateListItemProps) => {
  return (
    <BdsTableRow className="template-list-item">
      <BdsTableCell>
        <BdsTypo variant="fs-14" bold={'semi-bold'}>
          {template.templateId}
        </BdsTypo>
      </BdsTableCell>
      <BdsTableCell>
        <BdsTypo variant="fs-14" bold={'semi-bold'}>
          {template.body?.text}
        </BdsTypo>
      </BdsTableCell>

      <BdsTableCell type="custom">
        <ButtonUseTemplate template={template} onTemplateClick={onTemplateClick} />
      </BdsTableCell>
    </BdsTableRow>
  );
};
