import {
  BdsButton,
  BdsGrid,
  BdsLoadingSpinner,
  BdsTable,
  BdsTableBody,
  BdsTableHeader,
  BdsTableRow,
  BdsTableTh,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react';
import { Alert } from 'components/Alert/Alert';
import { ApplicationDetail } from 'configurations/Environment';
import { TOAST_FAILURE, TOAST_GET_FAILURE_MESSAGE } from 'constants/ToastMessages';
import { Analytics, Logger } from 'infra/adapters';
import { Template } from 'objects/types/MessageTemplate';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';
import { getMessagesTemplates } from 'services/BlipGoApiService';
import { createToastError } from 'services/Toats';
import { formatLogMessage } from 'utils/LogMessage';
import { ActiveMessagesNotRegistered } from '../ActiveMessagesNotRegistered/ActiveMessagesNotRegistered';
import { TemplateListItem } from '../TemplateListItem/TemplateListItem';
import './style.scss';

export interface IActiveMessagesProViewSelectTemplateProps {
  handleTemplateClick: (template: Template) => void;
}

export const ActiveMessagesProViewSelectTemplate = ({
  handleTemplateClick,
}: IActiveMessagesProViewSelectTemplateProps) => {
  const className = 'ActiveMessagesProViewSelectTemplate';
  const { tenant, router } = useSelector(useInstallation);
  const { whatsAppActive } = useSelector(useProject);
  const [messageTemplates, setMessageTemplates] = useState<Template[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openRedirectConfirmation, setOpenRedirectConfirmation] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const sendActiveMessagesUrl = `https://${tenant.id}.${ApplicationDetail}/${router.shortName}/contents/messagetemplate`;

  useEffect(() => {
    if (whatsAppActive) {
      loadTemplates();
    }
  }, []);

  const loadTemplates = async () => {
    const methodName = 'loadTemplates';
    try {
      setIsLoading(true);
      const res = await getMessagesTemplates(tenant.id, router.shortName);
      setMessageTemplates(res);
    } catch (ex) {
      createToastError(TOAST_FAILURE, TOAST_GET_FAILURE_MESSAGE);
      Logger.Error(formatLogMessage(ex, 'Failed to get template messages.'), {
        methodName,
        className,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRedirectConfirm = () => {
    window.open(sendActiveMessagesUrl, '_blank');
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_SEND);
    setOpenRedirectConfirmation(false);
  };

  const handleReload = () => {
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_MESSAGE_REFRESH);
    loadTemplates();
  };

  const handleDisable = () => {
    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, 10000);
  };

  const LoadingSpinner = () => (
    <BdsGrid justifyContent="center" alignItems="center" height="100%">
      <BdsLoadingSpinner />
    </BdsGrid>
  );

  const NoTemplatesMessage = () => <ActiveMessagesNotRegistered />;

  const TemplatesHeader = () => (
    <BdsGrid justifyContent="space-between" alignItems="center">
      <BdsTypo variant="fs-20" bold="bold" margin={false}>
        Use uma mensagem pré-aprovada para fazer o disparo
      </BdsTypo>
      <BdsButton
        className="button-create-template"
        variant="primary"
        onBdsClick={() => setOpenRedirectConfirmation(true)}
        data-testid="button-create-template"
      >
        Nova mensagem
      </BdsButton>
    </BdsGrid>
  );

  const TemplatesTable = ({ templates }: { templates: Template[] | undefined }) => (
    <BdsTable className="templates-table">
      <BdsTableHeader className="templates-table-header">
        <BdsTableRow>
          <BdsTableTh>Nome</BdsTableTh>
          <BdsTableTh>Mensagem</BdsTableTh>
          <BdsTableTh>
            <BdsButton
              onBdsClick={() => {
                handleReload();
                handleDisable();
              }}
              type-icon="icon"
              icon-left="refresh"
              variant="text"
              disabled={isDisabled}
              color="content"
            >
              Atualizar
            </BdsButton>
          </BdsTableTh>
        </BdsTableRow>
      </BdsTableHeader>
      <BdsTableBody>
        {templates?.map((template, index) => {
          return (
            <TemplateListItem key={index} template={template} onTemplateClick={() => handleTemplateClick(template)} />
          );
        })}
      </BdsTableBody>
    </BdsTable>
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (messageTemplates && messageTemplates.length === 0) {
    return <NoTemplatesMessage />;
  }

  return (
    <>
      <TemplatesHeader />
      <TemplatesTable templates={messageTemplates} />
      <Alert
        open={openRedirectConfirmation}
        title="Criar modelo ?"
        description="Você será direcionado para a plataforma Blip para criar um modelo para sua mensagem."
        icon="info"
        variant="system"
        onCancel={() => setOpenRedirectConfirmation(false)}
        onConfirm={handleRedirectConfirm}
      />
    </>
  );
};
